import React, { useEffect, useState } from "react";
import {
  Divider,
  Fab,
  Grid,
  Box,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";
import CrossIcon from "@mui/icons-material/Delete";
import StopIcon from "@mui/icons-material/Stop";
import moment from "moment";
import Microphone from "../audio/Microphone";
import MessagesContainer from "./MessagesContainer";
import ChatNavSection from "./ChatNavSection";

const ChatScreen = () => {
  const [tempFile, setTempFile] = useState(null);
  const [record, setRecord] = useState(false);
  const [showTextField, setShowTextField] = useState(true);
  const [textMessage, setTextMessage] = useState("");
  const [messages, setMessages] = useState([
    {
      time: "4:30 PM",
      sender: "UserA",
      content: "Hey man, What's up ?",
    },
    {
      time: "4:31 PM",
      sender: "UserB",
      content: "Hey, Iam Good! What about you ?",
    },
    {
      time: "4:32 PM",
      sender: "UserA",
      content: "Cool. i am good, let's catch up!",
    },
  ]);

  const dummyResponse = () => {
    setTimeout(
      () =>
        setMessages((prevMessage) => [
          ...prevMessage,
          {
            time: moment(new Date()).format("LT"),
            sender: "UserB",
            content: "Yeah",
          },
        ]),
      [2000]
    );
  };

  const pushFile = (file) => {
    setMessages((prevMessage) => [
      ...prevMessage,
      {
        time: moment(new Date()).format("LT"),
        sender: "UserA",
        attachments: {
          url: file,
        },
      },
    ]);

    dummyResponse();
  };

  const pushMessage = (msg) => {
    setMessages((prevMessage) => [
      ...prevMessage,
      {
        time: moment(new Date()).format("LT"),
        sender: "UserA",
        content: msg,
      },
    ]);

    dummyResponse();
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (tempFile) {
      pushFile(tempFile);
      setTempFile(null);
    } else if (textMessage) {
      pushMessage(textMessage);
      setTextMessage("");
    } else {
      setRecord(false);
    }
  };

  const startRecording = async () => {
    if (tempFile) setTempFile(null);
    // Check for microphone permission
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      // You can proceed with recording setup here
      setRecord(true);
    } catch (error) {
      // Handle permission denied or error
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    if (tempFile) setTempFile(null);
    setRecord(false);
  };

  useEffect(() => {
    if (record || tempFile) {
      setShowTextField(false);
    }
    // Show the TextField after a short delay
    const timeout = setTimeout(() => {
      if (!record && !tempFile) setShowTextField(true);
    }, 200); // You can adjust the delay time as needed

    return () => clearTimeout(timeout);
  }, [record, tempFile]);

  return (
    <Box sx={{ margin: "50px" }}>
      <Grid container component={Paper} elevation={2}>
        <Grid
          item
          xs={3}
          sx={{
            display: { xs: "none", md: "block" },
            borderRight: "1px solid #e0e0e0",
          }}
        >
          <Grid item xs={12} sx={{ padding: "18px" }}>
            <TextField
              id="outlined-basic-email"
              label="Search"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Divider />
          <ChatNavSection />
        </Grid>
        <Grid item xs={12} md={9}>
          <MessagesContainer messages={messages} />
          <Divider />
          <form onSubmit={sendMessage}>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              p={{ xs: 1, md: 2 }}
              spacing={{ xs: 1, md: 2 }}
              style={{ height: 130 }}
            >
              <Grid item xs={8} sm={10}>
                <div
                  style={{
                    display: !showTextField ? "flex" : "none",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Microphone
                    tempFile={tempFile}
                    record={record}
                    setRecord={setRecord}
                    setTempFile={setTempFile}
                  />
                </div>
                {showTextField && (
                  <TextField
                    fullWidth
                    id="outlined-basic-email"
                    placeholder="Message"
                    value={textMessage}
                    onChange={(e) => setTextMessage(e.target.value.trimStart())}
                  />
                )}
              </Grid>
              <Grid item xs={4} sm={2} align="right">
                <Stack
                  direction="row"
                  justifyContent="center"
                  spacing={{ xs: 1, md: 2 }}
                >
                  {record || tempFile ? (
                    <Fab color="error" aria-label="add" onClick={stopRecording}>
                      {tempFile ? <CrossIcon /> : <StopIcon />}
                    </Fab>
                  ) : (
                    <Fab
                      color="primary"
                      aria-label="add"
                      onClick={startRecording}
                    >
                      <MicIcon />
                    </Fab>
                  )}
                  <Fab type="submit" color="primary" aria-label="add">
                    <SendIcon />
                  </Fab>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChatScreen;
