import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

import ChatHeader from "./ChatHeader";

import AudioPlayer from "../audio/AudioPlayer";
import PerfectScrollbarWrapper from "../../../components/perfectScrollbar";

const useStyles = makeStyles({
  messageArea: {
    height: "60vh",
    overflowY: "auto",
  },
});

const MessagesContainer = ({ messages }) => {
  const classes = useStyles();

  return (
    <>
      <ChatHeader />
      <Divider />
      <List className={classes.messageArea}>
        <PerfectScrollbarWrapper>
          {messages.map((message, index) => (
            <ListItem key={index}>
              <Grid container>
                <Grid item xs={12}>
                  {message?.content ? (
                    <ListItemText
                      align={message.sender === "UserA" ? "right" : "left"}
                      primary={message?.content}
                    />
                  ) : (
                    <Stack
                      alignItems={
                        message.sender === "UserA" ? "flex-end" : "flex-start"
                      }
                    >
                      <AudioPlayer file={message.attachments.url} />
                    </Stack>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <ListItemText
                    align={message.sender === "UserA" ? "right" : "left"}
                    secondary={message?.time}
                  ></ListItemText>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </PerfectScrollbarWrapper>
      </List>
    </>
  );
};

export default MessagesContainer;
