import React from "react";
import { ReactMic } from "react-mic";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  reactmic: {
    height: "50px",
    width: "100%",
  },
}));

export default function AudioStream({ record, setTempFile, onStop }) {
  const onData = async (recordedBlob) => {
    // console.log("chunk of real-time data is: ", recordedBlob);
  };

  const classes = useStyles();

  return (
    <Grid container alignItems={"center"} sx={{ padding: "15px 5px" }}>
      <Grid item xs={12} display="flex" justifyContent={"flex-end"}>
        <ReactMic
          record={record}
          className={classes.reactmic}
          onStop={onStop}
          onData={onData}
          strokeColor="grey"
          backgroundColor="white"
        />
      </Grid>
    </Grid>
  );
}
