import {
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

import AudioPlayer from "../audio/AudioPlayer";
import PerfectScrollbarWrapper from "../../../components/perfectScrollbar";
import Translation from "./Translation";

const useStyles = makeStyles({
  messageArea: {
    height: "70vh",
    overflowY: "auto",
  },
});

const StreamContainer = ({ messages, loader }) => {
  const classes = useStyles();

  return (
    <List className={classes.messageArea}>
      <PerfectScrollbarWrapper>
        {messages.length
          ? messages.map((msg, index) => (
              <ListItem key={index}>
                <Grid
                  container
                  spacing={2}
                  m={2}
                  sx={{ border: ".5px solid #e0e0e0" }}
                >
                  <Grid item xs={12}>
                    <AudioPlayer file={msg.attachments.url} />
                  </Grid>
                  <Grid item xs={12}>
                    <ListItemText>
                      {msg.aws?.transcription && (
                        <Translation
                          title={"Transcript"}
                          lan={msg.aws?.transcriptionLanguage}
                          desc={msg.aws.transcription}
                        />
                      )}
                      {msg.aws?.translation && (
                        <Translation
                          title={"Translation"}
                          lan={msg.aws?.translationLanguage}
                          desc={msg.aws.translation}
                        />
                      )}
                      {msg.aws?.rephrased && (
                        <Translation
                          title={"Rephrasing"}
                          lan={msg.aws?.rephrasedLanguage}
                          desc={msg.aws.rephrased}
                        />
                      )}
                    </ListItemText>
                  </Grid>
                </Grid>
              </ListItem>
            ))
          : null}
        {loader ? (
          <Box display="flex" justifyContent="center" my={10}>
            <CircularProgress />
          </Box>
        ) : null}
      </PerfectScrollbarWrapper>
    </List>
  );
};

export default StreamContainer;
