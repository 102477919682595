import React, { useState, useRef, useEffect } from "react";
import { ReactMic } from "react-mic";
import WaveSurfer from "wavesurfer.js";
import { makeStyles } from "@mui/styles";
import { Grid, IconButton, Typography } from "@mui/material";
import {
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon,
} from "@mui/icons-material";

import "./microphone.css";

const useStyles = makeStyles((theme) => ({
  reactmic: {
    height: "50px",
    width: "100%",
  },
}));

export default function Microphone({ record, tempFile, setTempFile }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const wavesurfer = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    return () => {
      setSeconds(0);
      setMinutes(0);
      clearInterval(interval);
    };
  }, [record]);

  useEffect(() => {
    if (seconds >= 60) {
      setMinutes((prevMinutes) => prevMinutes + 1);
      setSeconds(0);
    }
  }, [seconds]);

  useEffect(() => {
    if (tempFile) {
      wavesurfer.current = WaveSurfer.create({
        container: "#wavesurfer-id",
        waveColor: "lightgrey",
        progressColor: "grey",
        height: 50,
        cursorWidth: 1,
        cursorColor: "lightgrey",
        barWidth: 3,
        normalize: true,
        responsive: true,
        fillParent: true,
      });
      wavesurfer.current.load(tempFile.blobURL);
      wavesurfer.current.on("play", () => setIsPlaying(true));
      wavesurfer.current.on("pause", () => setIsPlaying(false));

      // Listen for the 'audioprocess' event to update the current time
      wavesurfer.current.on("audioprocess", () => {
        setCurrentTime(wavesurfer.current.getCurrentTime());
      });

      // Set duration when the 'ready' event is fired
      wavesurfer.current.on("ready", () => {
        setDuration(wavesurfer.current.getDuration());
      });
    }
  }, [tempFile]);

  const togglePlayback = () => {
    if (!isPlaying) {
      wavesurfer.current.play();
    } else {
      wavesurfer.current.pause();
    }
  };

  const onData = (recordedBlob) => {
    //console.log("chunk of real-time data is: ", recordedBlob);
  };

  const onStop = (recordedBlob) => {
    setTempFile(recordedBlob);
  };

  const classes = useStyles();

  return (
    <>
      {tempFile ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "15px 5px" }}
        >
          <Grid item xs={2}>
            {!isPlaying ? (
              <Typography variant="body2" color="GrayText" align="center">
                {duration.toFixed(2)}
              </Typography>
            ) : (
              <Typography variant="body2" color="GrayText" align="center">
                {currentTime.toFixed(2)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={8}>
            <div id="wavesurfer-id" />
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"center"}>
            {!isPlaying ? (
              <IconButton color="primary" onClick={togglePlayback}>
                <PlayArrowIcon />
              </IconButton>
            ) : (
              <IconButton color="primary" onClick={togglePlayback}>
                <PauseIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container alignItems={"center"} sx={{ padding: "15px 5px" }}>
          <Grid item xs={2}>
            <Typography variant="body2" color="GrayText" align="center">
              {`${minutes.toString().padStart(2, "0")}:${seconds
                .toString()
                .padStart(2, "0")} `}
            </Typography>
          </Grid>
          <Grid item xs={8} display="flex" justifyContent={"flex-end"}>
            <ReactMic
              record={record}
              className={classes.reactmic}
              onStop={onStop}
              onData={onData}
              strokeColor="grey"
              backgroundColor="white"
            />
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      )}
    </>
  );
}
