import React from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";

const ChatHeader = () => {
  return (
    <List>
      <ListItem key="RemySharp" sx={{ justifyContent: "center" }}>
        <ListItemIcon>
          <Avatar
            alt="Remy Sharp"
            src="https://material-ui.com/static/images/avatar/1.jpg"
          />
        </ListItemIcon>
        <Stack>
          <ListItemText primary="John Wick"></ListItemText>
          <ListItemText secondary="last seen 30 minutes ago"></ListItemText>
        </Stack>
      </ListItem>
    </List>
  );
};

export default ChatHeader;
