import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import Grid from "@mui/material/Grid";
import WaveSurfer from "wavesurfer.js";

import { v4 as uuidv4 } from "uuid";
import { Fab, Typography } from "@mui/material";
import Theme from "../../../config/theme";

const AudioPlayer = ({ file }) => {
  const wavesurfer = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const wavesurferId = useMemo(() => `wavesurfer--${uuidv4()}`, []);

  useEffect(() => {
    if (!wavesurfer.current && file) {
      wavesurfer.current = WaveSurfer.create({
        container: `#${wavesurferId}`,
        waveColor: "lightgrey",
        progressColor: "grey",
        height: 50,
        cursorWidth: 1,
        cursorColor: "lightgray",
        barWidth: 3,
        normalize: true,
        responsive: true,
        fillParent: true,
      });
      wavesurfer.current.load(file.blobURL);
      wavesurfer.current.on("play", () => setIsPlaying(true));
      wavesurfer.current.on("pause", () => setIsPlaying(false));

      // Listen for the 'audioprocess' event to update the current time
      wavesurfer.current.on("audioprocess", () => {
        setCurrentTime(wavesurfer.current.getCurrentTime());
      });
      wavesurfer.current.on("ready", () => {
        setDuration(wavesurfer.current.getDuration());
      });
    }
  }, [file, wavesurferId]);

  const togglePlayback = () => {
    if (!isPlaying) {
      wavesurfer.current.play();
    } else {
      wavesurfer.current.pause();
    }
  };

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        bgcolor: "#F0F1FF",
        borderRadius: "34px",
        maxWidth: 448,
        minWidth: 240,
        padding: "15px 5px",
      }}
    >
      <Grid item xs={2} display={"flex"} justifyContent={"center"}>
        {!isPlaying ? (
          <Fab
            color="primary"
            size="small"
            sx={{
              backgroundColor: Theme.COLORS.PRIMARY_BUTTON,
              boxShadow: "none",
            }}
            onClick={togglePlayback}
          >
            <PlayArrowIcon />
          </Fab>
        ) : (
          <Fab
            color="primary"
            size="small"
            sx={{
              backgroundColor: Theme.COLORS.PRIMARY_BUTTON,
              boxShadow: "none",
            }}
            onClick={togglePlayback}
          >
            <PauseIcon />
          </Fab>
        )}
      </Grid>
      <Grid item xs={8} id={wavesurferId} />
      <Grid item xs={2}>
        {!isPlaying ? (
          <Typography variant="body2" color="GrayText" align="center">
            {duration?.toFixed(2)}
          </Typography>
        ) : (
          <Typography variant="body2" color="GrayText" align="center">
            {currentTime?.toFixed(2)}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default memo(AudioPlayer);
