import React, { useState, useRef, useEffect, memo } from "react";
import WaveSurfer from "wavesurfer.js";
import { Grid, IconButton, Typography } from "@mui/material";
import {
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon,
} from "@mui/icons-material";

const Player = ({ tempFile }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const wavesurfer = useRef(null);

  useEffect(() => {
    if (tempFile) {
      wavesurfer.current = WaveSurfer.create({
        container: "#wavesurfer-id",
        waveColor: "lightgrey",
        progressColor: "grey",
        height: 50,
        cursorWidth: 1,
        cursorColor: "lightgrey",
        barWidth: 3,
        normalize: true,
        responsive: true,
        fillParent: true,
      });
      wavesurfer.current.load(tempFile.blobURL);
      wavesurfer.current.on("play", () => setIsPlaying(true));
      wavesurfer.current.on("pause", () => setIsPlaying(false));

      // Listen for the 'audioprocess' event to update the current time
      wavesurfer.current.on("audioprocess", () => {
        setCurrentTime(wavesurfer.current.getCurrentTime());
      });

      // Set duration when the 'ready' event is fired
      wavesurfer.current.on("ready", () => {
        setDuration(wavesurfer.current.getDuration());
      });
    }
  }, [tempFile]);

  const togglePlayback = () => {
    if (!isPlaying) {
      wavesurfer.current.play();
    } else {
      wavesurfer.current.pause();
    }
  };
  //   console.log("im here", tempFile);
  return (
    <Grid container justifyContent="center" alignItems="center" my={2}>
      <Grid item xs={2}>
        {!isPlaying ? (
          <Typography variant="body2" color="GrayText" align="center">
            {duration.toFixed(2)}
          </Typography>
        ) : (
          <Typography variant="body2" color="GrayText" align="center">
            {currentTime.toFixed(2)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={8}>
        <div id="wavesurfer-id" />
      </Grid>
      <Grid item xs={2} display={"flex"} justifyContent={"center"}>
        {!isPlaying ? (
          <IconButton color="primary" onClick={togglePlayback}>
            <PlayArrowIcon />
          </IconButton>
        ) : (
          <IconButton color="primary" onClick={togglePlayback}>
            <PauseIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};
export default memo(Player);
