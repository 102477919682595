import React, { useState } from "react";
import { Fab, Grid, Box, Paper, Stack, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";
import DeleteIcon from "@mui/icons-material/Delete";
import StopIcon from "@mui/icons-material/Stop";
import moment from "moment";
import audioBufferToWav from "audiobuffer-to-wav";
import StreamContainer from "./StreamContainer";
import AudioStream from "./AudioStream";
import axios from "../../../utils/axios";
import Player from "./Player";

const StreamScreen = () => {
  const [tempFile, setTempFile] = useState(null);
  const [record, setRecord] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleAudioData = async (file) => {
    try {
      setLoader(true);
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const audioData = await file.blob.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(audioData);
      const wavData = audioBufferToWav(audioBuffer);
      const wavBlob = new Blob([wavData], { type: "audio/wav" });

      const formData = new FormData();
      formData.append("file", wavBlob, "audioInterval.wav");
      formData.append("transcription_provider", "aws");
      formData.append("is_translation_enabled", true);
      formData.append("translation_provider", "aws");
      formData.append("translation_language", "en");
      formData.append("is_rephrasing_enabled", true);
      formData.append("rephrasing_provider", "openai");

      const { data: response } = await axios.post(
        "/api/process_audio",
        formData,
        {
          rawRequest: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = response.data;
      if (data) {
        return {
          transcription: data.transcription,
          transcriptionLanguage: data.transcription_language,
          transcriptionProvider: data.transcription_provider,
          //
          translation: data.translation,
          translationLanguage: data.translation_language,
          translationProvider: data.translation_provider,
          //
          rephrased: data.rephrased_Text,
          rephrasedLanguage: data.rephrasing_Language,
          rephrasedProvider: data.rephrasing_provider,
        };
      }
    } catch (err) {
      console.log("error => ", err);
    } finally {
      setLoader(false);
    }
  };

  const pushFile = async (file) => {
    const responseData = await handleAudioData(file);
    setMessages((prevMessage) => [
      ...prevMessage,
      {
        time: moment(new Date()).format("LT"),
        sender: "UserA",
        attachments: {
          url: file,
        },
        aws: responseData,
      },
    ]);
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (tempFile) {
      pushFile(tempFile);
      setTempFile(null);
    } else {
      setRecord(false);
    }
  };

  const startRecording = async () => {
    if (tempFile) setTempFile(null);
    // Check for microphone permission
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      // You can proceed with recording setup here
      setRecord(true);
    } catch (error) {
      // Handle permission denied or error
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = (data) => {
    if (tempFile) setTempFile(null);
    setRecord(false);
  };

  const handleDataOnStop = (recordedBlob) => {
    setTempFile(recordedBlob);
  };

  return (
    <Box sx={{ margin: "50px 150px" }}>
      <Grid container component={Paper} elevation={2}>
        <Grid
          item
          xs={12}
          md={4}
          p={2}
          sx={{
            borderRight: { md: "1px solid #e0e0e0" },
            borderBottom: { xs: "1px solid #e0e0e0" },
          }}
        >
          {tempFile ? (
            <Player tempFile={tempFile} />
          ) : (
            <AudioStream
              tempFile={tempFile}
              record={record}
              setRecord={setRecord}
              setTempFile={setTempFile}
              onStop={handleDataOnStop}
            />
          )}
          <form onSubmit={sendMessage}>
            <Stack
              direction="row"
              justifyContent="center"
              spacing={{ xs: 1, md: 2 }}
            >
              {record || tempFile ? (
                <Fab color="error" aria-label="add" onClick={stopRecording}>
                  {tempFile ? <DeleteIcon /> : <StopIcon />}
                </Fab>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={startRecording}
                  endIcon={<MicIcon />}
                >
                  Click to Talk
                </Button>
              )}
              {tempFile && (
                <Fab
                  type="submit"
                  color="primary"
                  aria-label="add"
                  disabled={loader}
                >
                  <SendIcon />
                </Fab>
              )}
            </Stack>
          </form>
        </Grid>
        <Grid item xs={12} md={8}>
          <StreamContainer loader={loader} messages={messages} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default StreamScreen;
