import { Navigate, useRoutes } from "react-router-dom";
// import { lazy } from "react";
import AuthGuard from "./auth/AuthGuard";
import ChatScreen from "./pages/agent/chat";
import Layout from "./pages/Layout";
import StreamScreen from "./pages/agent/stream";

// const ChatScreen = lazy(() => import("./pages/agent/chat"));

export default function Router() {
  const routes = [
    {
      path: "/",
      element: (
        <AuthGuard>
          <Layout />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <Navigate to="/streaming" /> },
        {
          path: "chat",
          element: <ChatScreen />,
        },
        {
          path: "streaming",
          element: <StreamScreen />,
        },
      ],
    },
  ];

  return useRoutes(routes);
}
