import React from "react";
import {
  Avatar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PerfectScrollbarWrapper from "../../../components/perfectScrollbar";

const useStyles = makeStyles({
  navArea: {
    height: "60vh",
    overflowY: "auto",
  },
});

const ChatNavSection = () => {
  const classes = useStyles();
  return (
    <PerfectScrollbarWrapper>
      <List className={classes.navArea}>
        <ListItemButton key="RemySharp">
          <ListItemIcon>
            <Avatar
              alt="Remy Sharp"
              src="https://material-ui.com/static/images/avatar/1.jpg"
            />
          </ListItemIcon>
          <ListItemText primary="Remy Sharp">Remy Sharp</ListItemText>
          <ListItemText secondary="online" align="right"></ListItemText>
        </ListItemButton>
        <ListItemButton key="Alice">
          <ListItemIcon>
            <Avatar
              alt="Alice"
              src="https://material-ui.com/static/images/avatar/3.jpg"
            />
          </ListItemIcon>
          <ListItemText primary="Alice">Alice</ListItemText>
        </ListItemButton>
        <ListItemButton key="CindyBaker">
          <ListItemIcon>
            <Avatar
              alt="Cindy Baker"
              src="https://material-ui.com/static/images/avatar/2.jpg"
            />
          </ListItemIcon>
          <ListItemText primary="Cindy Baker">Cindy Baker</ListItemText>
        </ListItemButton>
      </List>
    </PerfectScrollbarWrapper>
  );
};

export default ChatNavSection;
