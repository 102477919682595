import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ResponsiveAppBar from "../components/navbar/index";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  minHeight: "100%",
  width: "100%",
});

export default function DashboardLayout() {
  return (
    <>
      <ResponsiveAppBar />
      <StyledRoot>
        <Outlet />
      </StyledRoot>
    </>
  );
}
