import { Grid, Typography } from "@mui/material";
import React from "react";

const Translation = ({ title, lan, desc }) => {
  return (
    <Grid container spacing={1} my={2}>
      <Grid item xs={12}>
        <Typography variant="h6">
          {title}
          <Typography color={"gray"} component="span" ml={1}>
            ({lan})
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color={"gray"}>{desc}</Typography>
      </Grid>
    </Grid>
  );
};

export default Translation;
